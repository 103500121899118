/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Image, Quote, QuoteAuthor} from "smooth-doc/components";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ol: "ol",
    li: "li",
    ul: "ul",
    strong: "strong",
    h2: "h2",
    blockquote: "blockquote",
    em: "em",
    code: "code",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "modeling-aggregates",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#modeling-aggregates",
    "aria-label": "modeling aggregates permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Modeling Aggregates"), "\n", React.createElement(_components.p, null, "Modeling aggregates with entities, command handlers and domain events."), "\n", React.createElement(_components.p, null, "Aggregates are central to DDD and ZenWave SDK modeling. They represent clusters of entities and values that works as transactional boundary and are treated as a single unit:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement("strong", {
    className: "orange"
  }, "Treated as a Single Unit"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement("strong", {
    className: "orange"
  }, "Root Entity"), ": Each aggregate has a root entity, also known as the aggregate root. All external references to the aggregate should only point to the aggregate root. This ensures the integrity of the aggregate."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement("strong", {
    className: "orange"
  }, "Consistency Boundaries"), ": The aggregate defines the transactional units of consistency boundaries."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement("strong", {
    className: "orange"
  }, "Reference by Identity"), ": Aggregates should be referenced by their identifiers (IDs) rather than direct object references. This promotes loose coupling between aggregates and enhances the system's scalability and maintainability."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement("strong", {
    className: "orange"
  }, "Invariants"), ": The aggregate root is responsible for maintaining all invariants within the aggregate. An invariant is a business rule that must always be consistent."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement("strong", {
    className: "orange"
  }, "Persistence"), ": Persistence of an aggregate should be done as a whole. It's the responsibility of the aggregate root to handle this."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement("strong", {
    className: "orange"
  }, "Size"), ": Ideally, an aggregate should be as small as possible, based on the business rules. A smaller aggregate is easier to maintain and understand."), "\n"), "\n"), "\n", React.createElement(_components.p, null, "ZenWave SDK supports these three different patterns to model aggregates:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "#lightweight-aggregates"
  }, "Lightweight/Data Centric:")), " When your application logic is simple."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "#rich-domain-aggregates"
  }, "Rich Domain Aggregates:")), " When you are dealing with complex business logic and in core subdomains."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "#event-sourced-aggregates"
  }, "Event Sourced Aggregates:")), " When implementing CQRS and Event Sourcing patterns."), "\n"), "\n", React.createElement(_components.h2, {
    id: "lightweight-aggregates",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#lightweight-aggregates",
    "aria-label": "lightweight aggregates permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Lightweight Aggregates"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "💡 If your application logic is simple, keep your implementation simple."), "\n"), "\n", React.createElement(_components.p, null, "Lightweight Aggregates are ", React.createElement("strong", {
    className: "orange"
  }, "simple data containers"), " that do not contain any business logic. They are used to group related data together. They are treated as a single unit and have a root entity but ", React.createElement("strong", {
    className: "orange"
  }, "related business logic is implemented in services"), " or other classes."), "\n", React.createElement(_components.p, null, "Most microservice applications look like:"), "\n", React.createElement("strong", {
    className: "orange"
  }, "\"Receive some command/request, validate, process, persist the data and emit an integration event for downstream services.\""), "\n", React.createElement(_components.p, null, "If your application logic is simple, keep your implementation simple."), "\n", React.createElement(_components.p, null, "This would be our ", React.createElement(_components.em, null, "first"), ", ", React.createElement(_components.em, null, "go-to"), " recommended approach. For most microservice applications this pattern is good enough to guarantee maintainability and scalability. And it, does not overcomplicate your codebase with unnecessary complexity. If you are in doubt, start with this pattern."), "\n", React.createElement(_components.p, null, "Sometimes you will find this referred as the ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Anemic_domain_model"
  }, "Anemic Domain Model"), " but it's ", React.createElement(_components.strong, null, "OK"), " for most microservice applications."), "\n", React.createElement(_components.p, null, "It applies ", React.createElement("strong", {
    className: "orange"
  }, "Keep-It-Simple (KISS)"), " principle and ", React.createElement("strong", {
    className: "orange"
  }, "Separation of Concerns"), ", clearly separating where data is defined (your entities) and the business process you apply to it (your services). Services are still the only entry point for your aggregates functionality, and it works just fine for most microservice applications."), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "@aggregate"), " annotation to mark your ", React.createElement("strong", {
    className: "orange"
  }, "aggregate root entity"), ", and use ", React.createElement(_components.strong, null, "service"), " for defining command/methods:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "@aggregate\r\nentity Customer {\r\n    // fields, nested objects and relationships...\r\n}\r\n\r\nservice CustomerService for (Customer) {\r\n  /* the following service method names and signatures will genereate CRUD operations */\r\n  createCustomer(Customer) Customer withEvents CustomerEvent\r\n  updateCustomer(id, Customer) Customer? withEvents CustomerEvent\r\n  deleteCustomer(id) withEvents CustomerEvent\r\n  getCustomer(id) Customer?\r\n  @paginated\r\n  listCustomers() Customer[]\r\n  /* this is will generate (almost) empty implementation placeholders for service and tests */\r\n  updateCustomerAddress(id, Address) Customer? withEvents CustomerEvent\r\n}\n")), "\n", React.createElement(_components.h2, {
    id: "rich-domain-aggregates",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rich-domain-aggregates",
    "aria-label": "rich domain aggregates permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rich Domain Aggregates"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "💡 Start with the Lightweight Aggregates pattern and refactor to Rich Domain Aggregates when you need it."), "\n"), "\n", React.createElement(_components.p, null, "Sometimes referred to as ", React.createElement("strong", {
    className: "orange"
  }, "Rich Domain Model"), " or ", React.createElement("strong", {
    className: "orange"
  }, "Rich Domain Objects"), ". These are objects that contain both data and behavior. They are not just data containers but also contain business logic."), "\n", React.createElement(_components.p, null, "We recommend following this approach when:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("strong", {
    className: "orange"
  }, "Business logic is complex"), " and the aggregate needs to maintain its own consistency and invariants."), "\n", React.createElement(_components.li, null, "Your ", React.createElement("strong", {
    className: "orange"
  }, "service methods spans multiple aggregates"), "."), "\n", React.createElement(_components.li, null, "You are modeling aggregates that need to ", React.createElement("strong", {
    className: "orange"
  }, "encapsulate state transitions"), "."), "\n", React.createElement(_components.li, null, "You are working on a ", React.createElement("strong", {
    className: "orange"
  }, "core subdomain"), "."), "\n"), "\n", React.createElement(_components.p, null, "With this approach, your service methods will look simpler, in expense of more complex, and bigger code base for your aggregates. And a stepper learning curve for your developing team."), "\n", React.createElement(_components.p, null, "If you don't know if you need it, you probably don't."), "\n", React.createElement(_components.h3, {
    id: "modeling-rich-domain-aggregates-with-zenwave-zdl",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#modeling-rich-domain-aggregates-with-zenwave-zdl",
    "aria-label": "modeling rich domain aggregates with zenwave zdl permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Modeling Rich Domain Aggregates with ZenWave ZDL"), "\n", React.createElement(_components.p, null, "This is how you would model this with ZenWave SDK:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "/**\r\n * Delivery Aggregate\r\n */\r\naggregate DeliveryAggregate (Delivery) {\r\n\r\n    createDelivery(DeliveryInput) withEvents DeliveryStatusUpdated\r\n\r\n    onOrderStatusUpdated(OrderStatusUpdated) withEvents DeliveryStatusUpdated\r\n\r\n    updateDeliveryStatus(DeliveryStatusInput) withEvents DeliveryStatusUpdated\r\n\r\n}\r\n// @aggregate\r\nentity Delivery {\r\n    // fields, nested objects and relationships...\r\n}\r\n\r\n@rest(\"/delivery\")\r\nservice DeliveryService for (DeliveryAggregate) {\r\n\r\n    @asyncapi({api: OrdersAsyncAPI, channel: \"OrderCreatedChannel\"})\r\n    createDelivery(DeliveryInput) Delivery withEvents DeliveryStatusUpdated\r\n\r\n    @asyncapi({api: OrdersAsyncAPI, channel: \"OrderUpdatesChannel\"})\r\n    onOrderStatusUpdated(id, OrderStatusUpdated) Delivery withEvents DeliveryStatusUpdated\r\n\r\n    @put(\"/{orderId}/status\")\r\n    updateDeliveryStatus(id, DeliveryStatusInput) Delivery withEvents DeliveryStatusUpdated\r\n\r\n    @get @paginated\r\n    listDeliveries() Delivery[]\r\n}\n")), "\n", React.createElement(_components.h3, {
    id: "rich-domain-aggregates-in-java",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rich-domain-aggregates-in-java",
    "aria-label": "rich domain aggregates in java permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rich Domain Aggregates in Java"), "\n", React.createElement(_components.p, null, "This is how a service for a rich aggregate looks like:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-java"
  }, "public class DeliveryServiceImpl implements DeliveryService {\r\n    @Transactional\r\n    public Delivery onOrderStatusUpdated(String id, OrderStatusUpdated input) {\r\n        var deliveryAggregate = deliveryRepository.findDeliveryAggregateById(id).orElseThrow();\r\n        deliveryAggregate.onOrderStatusUpdated(input); // the aggregate business logic\r\n        persistAggregate(deliveryAggregate);\r\n        emitEvents(deliveryAggregate.getEvents());\r\n        return deliveryAggregate.getRootEntity();\r\n    }\r\n}\n")), "\n", React.createElement(_components.p, null, "And the aggregate itself:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-java"
  }, "/**\r\n* Rich Domain Aggregate for Delivery.\r\n*/\r\npublic class DeliveryAggregate {\r\n\r\n    private final Delivery rootEntity;\r\n\r\n    private final List<Object> events = new ArrayList<>();\r\n\r\n    // getters, setters, constructor, etc.\r\n\r\n    /**\r\n    * Performs business logic related to 'onOrderStatusUpdated' and emits 'DeliveryStatusUpdatedEvent' domain event.\r\n    */\r\n    public void onOrderStatusUpdated(OrderStatusUpdated input) {\r\n        // TODO: your business logic would be here\r\n        mapper.update(rootEntity, input);\r\n        events.add(mapper.asDeliveryStatusUpdated(rootEntity));\r\n    }\r\n\r\n    @org.mapstruct.Mapper\r\n    interface Mapper {\r\n\r\n        Delivery update(@MappingTarget Delivery entity, OrderStatusUpdated input);\r\n\r\n        DeliveryStatusUpdatedEvent asDeliveryStatusUpdated(Delivery entity);\r\n\r\n    }\r\n}\r\n\r\n/**\r\n* Delivery JPA Entity, used as rootEntity.\r\n*/\r\n@Entity(name = \"delivery\")\r\n@lombok.Getter\r\n@lombok.Setter(AccessLevel.PACKAGE) // disallow direct access to setters from service layer\r\npublic class Delivery implements Serializable {\r\n    // ...\r\n}\r\n\r\n/**\r\n* Delivery Updated Domain Event.\r\n*/\r\npublic class DeliveryStatusUpdatedEvent implements Serializable {\r\n    // ...\r\n}\n")), "\n", React.createElement(_components.h2, {
    id: "event-sourced-aggregates",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#event-sourced-aggregates",
    "aria-label": "event sourced aggregates permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Event Sourced Aggregates"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "💡 This is and extension of the Rich Domain Aggregates, where aggregate commands are event handlers that reconstruct the aggregate state."), "\n"), "\n", React.createElement(_components.p, null, "With ZenWave SDK you can model aggregates that are event sourced, whose state is created as a sequence of events. This is common when implementing the CQRS pattern."), "\n", React.createElement(_components.p, null, "This section is not about how to store the state of an aggregate as a sequence of events, but how aggregates state is rebuilt from incoming events."), "\n", React.createElement(_components.p, null, "This approach is just an extension of the Rich Domain Aggregates pattern, where aggregate commands are event handlers that reconstruct the aggregate state."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "/**\r\n * Delivery Aggregate\r\n */\r\naggregate DeliveryAggregate (Delivery) {\r\n    onOrderCreatedEvent(OrderCreatedEvent) withEvents DeliveryStatusUpdated\r\n    onOrderStatusUpdated(OrderStatusUpdatedEvent) withEvents DeliveryStatusUpdated\r\n    onOrderCancelled(OrderCancelledEvent) withEvents DeliveryStatusUpdated\r\n}\r\n\r\nservice DeliveryService for (DeliveryAggregate) {\r\n    @asyncapi({api: OrdersAsyncAPI, channel: \"OrderCreatedChannel\"})\r\n    onOrderCreatedEvent(OrderCreatedEvent) withEvents DeliveryStatusUpdated\r\n    @asyncapi({api: OrdersAsyncAPI, channel: \"OrderUpdatedChannel\"})\r\n    onOrderStatusUpdated(OrderStatusUpdatedEvent) withEvents DeliveryStatusUpdated\r\n    @asyncapi({api: OrdersAsyncAPI, channel: \"OrderDeletedChannel\"})\r\n    onOrderCancelled(OrderCancelledEvent) withEvents DeliveryStatusUpdated\r\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
